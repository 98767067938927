<template>
  <NuxtLayout name="catalog">
    <div class="page-404">
      <div class="page-container !max-w-[1200px]">
        <div class="flex items-center justify-center aspect-[4/1] p-8 text-xl font-bold tracking-tight text-center text-neutral-50 bg-[url('/img/404.jpg')] bg-cover bg-center tablet:text-3xl">
          <div class="tablet:max-w-[300px]">
            <div class="mb-1 text-3xl tablet:mb-4 tablet:text-5xl">404</div>
            {{ $t('Je nám líto, ale požadovaná stránka neexistuje') }}
          </div>
        </div>
      </div>
      <div class="my-4 py-4 bg-neutral-100 tablet:py-8 tablet:my-8 desktop:py-12 desktop:my-12">
        <div class="page-container !max-w-[1200px]">

          <div class="flex flex-wrap gap-4">

            <AtomsContainersCornerBorders class="page-404__block">
              <div class="page-404__block__header">{{ $t('Zkuste hledání') }}</div>
              <div class="page-404__block__desc">{{ $t('Nejrychlejší způsob, jak najít, co hledáte. Hledat za Vás totiž bude počítač.') }}</div>
              <MoleculesButtonsButton :arrow-right="true" @click="openSearch">{{ $t('Hledat') }}</MoleculesButtonsButton>
            </AtomsContainersCornerBorders>

            <AtomsContainersCornerBorders class="page-404__block">
              <div class="page-404__block__header">{{ $t('Přejděte na Homepage') }}</div>
              <div class="page-404__block__desc">{{ $t('Podívejte se na hlavní stránku, kde najdete vše důležité a zajímavé.') }}</div>
              <MoleculesButtonsButton :arrow-right="true" href="/">{{ $t('Homepage') }}</MoleculesButtonsButton>
            </AtomsContainersCornerBorders>

            <AtomsContainersCornerBorders class="page-404__block">
              <div class="page-404__block__header">{{ $t('Kontaktujte nás') }}</div>
              <div class="page-404__block__desc">{{ $t('Chcete osobnější kontakt? Zavolejte, napište, nebo navštivte naši prodejnu.') }}</div>
              <MoleculesButtonsButton :arrow-right="true" :href="cache.getPageUrl(3453)">{{ $t('Kontakty') }}</MoleculesButtonsButton>
            </AtomsContainersCornerBorders>

          </div>
        </div>
      </div>
      <div class="page-container">
        <TemplatesRecentlyViewedItems />
        <TemplatesNewItems />
      </div>
    </div>

  </NuxtLayout>
</template>
<script setup>

const event = useRequestEvent()
const cache = useCache();

setResponseStatus(event, 404);

useHead({
  title: `Stránka nenalezena`
});

const openSearch = () => {

  useState('isSearchOpen', () => false).value = true;

  if (process.client) {
    nextTick(() => {
      document.getElementById('txtSearch').focus();
    });
  }

};

</script>
<style lang="postcss">
.page-404 {

  @apply my-4 tablet:my-8 desktop:my-12;

  &__block {
    @apply flex flex-col justify-between w-full p-6 text-center bg-neutral-50 tablet:flex-1;

    &__header {
      @apply text-xl font-bold uppercase tracking-normal;
    }

    &__desc {
      @apply my-4;
    }

    .button {
      @apply w-full;
    }
  }
}
</style>